.errorsWrap{
  display: none;
  flex-direction: column;
  width: 100%;
  padding: 10px 15px;
  margin-top: 10px;
  background: #ffedef;
  border-radius: 3px;
  margin-bottom: 20px;

  &.withErrors{
    display: flex;
  }
}

.textError{
  margin-top: 4px;

  &:first-child{
    margin-top: 0;
  }
}

.text{
  line-height: 18px;
  letter-spacing: .8px;
}
